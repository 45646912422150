// import TomSelect from "tom-select/dist/js/tom-select.base.js";
// import TomSelect_clear_button from "tom-select/dist/js/plugins/clear_button.js";

import TomSelect from "tom-select/src/tom-select";
import clear_button from "tom-select/src/plugins/clear_button/plugin";

import { defaultSelectSettings } from "./modules/functions";
const { __ } = wp.i18n;

TomSelect.define("clear_button", clear_button);
TomSelect.define("add_label", function (options) {
  this.hook("after", "setup", function () {
    const label = document.createElement("label");
    const arrow = document.createElement("div");

    this.dropdown.classList.add("!-bottom-1.5");
    this.control_input.setAttribute(
      "class",
      "peer w-full flex-1 select-none outline-0 ring-0 bg-transparent [.has-items_&]:hidden"
    );

    if (options.label) {
      this.control.setAttribute(
        "class",
        "ts-control flex gap-1 items-center relative pr-14 [&.invalid]:bg-red-girl [&.valid]:bg-green [&.valid]:shadow-input-valid flex-1 rounded border-none bg-grey-theme px-4 pb-1 pt-5 shadow-btn-primary focus-widthin:shadow-input-active hover:lg:shadow-input-active transition [.disabled_&]:shadow-default"
      );
      label.setAttribute(
        "class",
        "pointer-events-none text-input-text absolute left-4 top-3 -translate-y-1/2 text-xs transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-[#8D8D8D] peer-placeholder-shown:top-1/2 [.has-items_&]:!top-3 peer-focus:top-3 [.has-items_&]:!text-input-text-disable [.disabled_&]:opacity-50 peer-focus:text-input-text-disable [.has-items_&]:!text-xs peer-focus:text-xs [.required~.ts-wrapper_&]:after:text-red-core [.required~.ts-wrapper_&]:after:content-['*']"
      );
      label.setAttribute("for", this.input.id);
      label.innerHTML = options.label;

      this.control_input.classList.add("placeholder-transparent");
      this.control.querySelector("input").after(label);
    } else {
      this.control.setAttribute(
        "class",
        "ts-control flex gap-1 items-center relative pr-14 [&.invalid]:bg-red-girl [&.valid]:bg-green [&.valid]:shadow-input-valid flex-1 rounded border-none bg-grey-theme px-4 pb-1 pt-5 shadow-btn-primary focus-widthin:shadow-input-active hover:lg:shadow-input-active transition [.disabled_&]:shadow-default [.disabled_&]:text-input-text-disable [.disabled_&]:placeholder:text-input-text-disable sm:py-2.5"
      );
    }

    // this.item.setAttribute("class", "item truncate");

    arrow.classList.add(
      "absolute",
      "top-1/2",
      "-translate-y-1/2",
      "right-4",
      "[.dropdown-active_&]:rotate-180",
      "transition-transform"
    );
    arrow.innerHTML = `<svg width="24" height="24" class="fill-current" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M9.37726 13.6417L15.7641 7.25486L15.7641 4.42261L9.37726 10.7771L9.37726 13.6417Z"/>
		<path d="M8.69197 13.6417L2.23584 7.18709L2.23584 4.35945L2.23892 4.35791L8.69197 10.8094L8.69197 13.6417Z"/>
		</svg>`;

    this.control.lastChild.after(arrow);
  });
});

document.TomSelect = TomSelect;

function setSelect() {
  document.querySelectorAll("select").forEach((el) => {
    if (el.tomselect) {
      return;
    }

    if (el.classList.contains("no-tom-select")) {
      return;
    }

    new TomSelect(el, defaultSelectSettings());
  });
}

window.addEventListener("DOMContentLoaded", () => {
  setSelect();

  if (document.querySelector(".search")) {
    const search = new TomSelect('.search',{
      valueField: 'title',
      labelField: 'title',
      searchField: ['title', 'content'],
        optgroupField: 'type',
      create: true,
      maxOptions: 50,
      optgroups : [
        {
          value: 'post',
          label: __("Posts", "frontenda-store-front")
        },
        {
          value: 'product',
          label: __("Products", "frontenda-store-front")
        },
      ],
      plugins: {
        'clear_button': {
          html : function(data){
            return `<div id="ts-clear-button" class="${data.className}" title="${data.title}">
              <svg class="fill-current" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                <rect x="2.55664" y="12.4463" width="14.076" height="1.5" rx="0.75" transform="rotate(-45 2.55664 12.4463)"/>
                <rect x="3.48975" y="2.49304" width="14.076" height="1.5" rx="0.75" transform="rotate(45 3.48975 2.49304)"/>
              </svg>
            </div>`;
          },
          title: __("Clear search form","frontenda-store-front"),
          className: "ts-clear-button"
        }
      },
      // fetch remote data
      load: function(query, callback) {
        fetch(`${window.theme.api.endpointTermo}/search`, {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
            "X-WP-Nonce": window.theme.api.nonce,
          },
          body: JSON.stringify({
            "_wpnonce": window.theme.api.nonce,
            "query": query,
            "lang": window.theme.lang,
          })
        })
          .then(response => response.json())
          .then(json => {
            callback(json);
          })
      },
      // custom rendering function for options
      onChange: function (value) {
        const link = this.getItem(value);
        window.location = link.dataset.href;
      },
      onInitialize: function () {
        const clearButton = document.getElementById('ts-clear-button');
        const searchInput = document.getElementById('search-ts-control');
    
        clearButton?.addEventListener('click', (e) => {
          searchInput.value = "";
        })
    
      },
        render: {
            optgroup_header: function({ label }, escape) {
          return `<div class="optgroup-header">${escape(label)}</span></div>`;
        },
        option: function({link, title, content, thumb}, escape) {
          return `<div data-href="${ escape(link) }" class="ts-option">
            <img class="ts-img" src="${ escape(thumb) }" />
            <div class="flex-1">
              <p class="h4 mb-1">${ escape(title) }</p>
              <p class="text-sm text-gray-700 line-clamp-2">${ escape(content.replace(/(<([^>]+)>)/gi, "")) }</p>
            </div>
            <svg class="stroke-current flex-none text-brand-red" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <line x1="7.36302" y1="19.295" x2="15.9882" y2="11.9372" stroke-linecap="round"/>
              <path d="M15.5016 11.7641L7.63813 4.09906" stroke-linecap="round"/>
            </svg>
          </div>`;
        },
        item: function({link, title}, escape) {
          return `<div data-href="${ escape(link) }">
            <div class="truncate">${ escape(title) }</div>
          </div>`;
        },
        no_results: function () {
          return __("Not founded","frontenda-store-front");
        },
        option_create: function(data, escape) {
          const search = __("Search","frontenda-store-front");
          return `<div class="ts-create">${search} <strong>${escape(data.input)}</strong>&hellip;</div>`;
        },
        loading: function(){
          return `<div class="absolute inset-0 flex justify-center items-center bg-white">
            <div class="animate-spin w-5 h-5 border-t border-r rounded-full border-brand-red"></div>
          </div>`;
        },
      },
    });
  }
});

window.addEventListener("facetwp-loaded", () => {
  setSelect();
});